<template>
    <div class="tabTop">
        <div class="logo_left">
            <div><img src="../../assets/login-tm.png" style=" position: absolute; width: 125px; height: 50px;" alt=""/></div>
        </div>
        <div>
            <div class="rightFun">
                <!-- 移动端项目显示 -->
<!--                <section class="icons" id="xiala" @click="xiala">-->
<!--                    &lt;!&ndash; <img src="../../assets/caidan.png" alt=""> &ndash;&gt;-->
<!--                    <div @click="tab"></div>-->
<!--                </section>-->
<!--                <div class="FunImg" @click="BigScreen">-->
<!--                    <el-tooltip-->
<!--                            class="item"-->
<!--                            effect="dark"-->
<!--                            content="全屏"-->
<!--                            placement="bottom"-->
<!--                    >-->
<!--                        <i class="el-icon-full-screen"></i>-->
<!--                    </el-tooltip>-->
<!--                </div>-->
                <!-- 移动端兼容问题 -->
                <div class="userImg">
                    <img v-if='userInfo==null || userInfo.headerUrl==null' src="../../assets/user.jpg" alt=""/>
                    <img v-if='userInfo!=null && userInfo.headerUrl' :src="userInfo.headerUrl" alt=""/>
                    <el-dropdown>
                        <div class="el-dropdown-link">
                            <i class="el-icon-arrow-down"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="uploadPwd">修改密码</el-dropdown-item>
                            <el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 显示隐藏 -->
                <section class="section" v-show="isTure">
                    <yidon></yidon>
                </section>
            </div>
        </div>
        <!-- 下拉按钮 -->
        <!-- <div >
    {{iszhuangtai}}
        </div> -->
        <el-dialog
                title="修改密码"
                :visible.sync="uploadPwdDialog"
                width="40%">
            <div>
                <el-form ref="ruleForm" :model="updateBO" :rules="rules" label-width="150px">
                    <el-form-item label="原密码:" prop="loginPassword">
                        <el-input v-model="updateBO.loginPassword" placeholder="原密码"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码:" prop="newLoginPassword1">
                        <el-input v-model="updateBO.newLoginPassword1" placeholder="新密码"></el-input>
                    </el-form-item>
                    <el-form-item label="再次输入新密码:" prop="newLoginPassword2">
                        <el-input v-model="updateBO.newLoginPassword2" placeholder="再次输入新密码"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadPwdDialog = false">取 消</el-button>
                <el-button type="primary" @click="uploadPwdCommit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import yidon from "../yidongduanaerjicaidan.vue";

    export default {
        components: {
            yidon,
        },
        data() {
            return {
                isTure: false,
                datalis: [
                    {}
                ],
                fullscreen: false,
                iszhuangtai: false,
                userInfo: {},
                uploadPwdDialog: false,
                updateBO: {
                    loginPassword: '',
                    newLoginPassword1: '',
                    newLoginPassword2: ''
                },
                rules: {
                    loginPassword: [
                        {required: true, message: "请输入原密码", trigger: "blur",},
                    ],
                    newLoginPassword1: [
                        {required: true, message: "请输入新密码", trigger: "blur",},
                    ],
                    newLoginPassword2: [
                        {required: true, message: "请再次输入新密码", trigger: "blur",},
                    ],
                }
            };
        },
        mounted() {
            let userInfo = localStorage.getItem("userInfo");
            this.userInfo = JSON.parse(userInfo);
        },
        methods: {
            uploadPwdCommit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.openLoadingView();
                        this.$axios_supermall.post('/hnjxjy-admin/sysLogin/updateLoginPassword', this.updateBO).then(response => {
                            if (response.data.code === 2000) {
                                this.$message.success("密码修改成功，请重新登录");
                              setTimeout(() => {
                                this.doLogOut();
                              }, 500);

                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            doLogOut() {
                this.openLoadingView();
                localStorage.setItem('siteId', null);
                localStorage.setItem('loginStrage', null);
                localStorage.setItem('userInfo', null);
                localStorage.setItem('permissionLink', null);
                localStorage.setItem('menuList', null);
                window.location.href = "/Online/index";
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            },
            loginOut() {
                this.$confirm("确定退出?", "退出提示", {
                    type: "warning",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    this.openLoadingView();
                    this.$axios_supermall.post('/hnjxjy-security/user/logout').then(response => {
                        if (response.data.code === 2000) {
                            this.doLogOut();
                        }
                    })
                }).catch(() => {
                    this.$message.success('取消退出');
                });

            },
            uploadPwd() {
                this.uploadPwdDialog = true;
            },
            BigScreen() {
                // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
                let element = document.getElementById('rigth');//设置后就是   id==con_lf_top_div 的容器全屏
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            },
            // 下拉按钮切换

            xiala() {

            },
            // 点击按钮tab切换
            tab: function () {
                this.isTure = !this.isTure;
            },
        }
    };
</script>
<style>
    html {
        font-size: 16px !important;
        background: #eee;
    }

    .tabTop {
        width: 100%;
        height: 3.3rem;
        padding: 1rem 2rem;
        box-sizing: border-box;
        background: #0f86f4;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        color: #fff;
    }

    .logo_left {
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    .logo_left p {
        height: 100%;
        line-height: 3.3rem;
        display: inline-block;
        margin: 0 0 0 1rem;
        padding-top: 4px;
    }

    .logo_left div {
        width: 3.3rem;
        height: 3.3rem;
    }

    .tabTop_logo img {
        width: 100%;
        vertical-align: middle;
    }

    /* 右侧 */
    .rightFun {
        display: flex;
        justify-content: flex-end;
        align-items: center;

    }

    .rightFun:hover {
        cursor: pointer;
    }

    .rightFun div {
        margin: 0 0.3rem;
    }

    .rightFun img {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        margin: 0 0.2rem 0 0;
    }

    .FunImg .el-tooltip {
        outline: none;
        background: rgba(0, 0, 0, 0);
        border: none;
        border-radius: 0;
        font-size: 1.3rem;
    }

    .FunImg i {
        margin: .2rem 0 0 0;
    }

    .FunImg .el-tooltip u {
        text-decoration: none;
        font-size: .8rem;
    }

    .userImg {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    .icons div {
        width: 2rem;
        height: 2rem;
        background: url(../../assets/caidan.png) no-repeat;
        background-size: cover;
        display: none;
    }

    .icons div:hover {
        background-image: url(../../assets/caidan.png);
    }


    .el-icon-arrow-down {
        color: #fff;
    }

    .icons {
        width: 2rem;
        height: 2rem;
    }

    .icons img {
        width: 100%;
    }

    @media only screen and (max-width: 750px) {
        .icons div {
            display: block;
        }

        .tabTop {
            padding: 0;
        }
    }
</style>