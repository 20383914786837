<template>
  <!-- 主题内容 -->
  <section class="box">
    <!-- {{list}} -->
    <ul class="uls">
      <!-- 遍历list -->
      <li v-for="(item, index) in list" :key="item.id" class="lis">
        <!-- 点击事件 -->
        <h4 @click="tab(index)">{{ item.title }}</h4>
        <!-- 显示/隐藏 -->
            <ol v-show="item.visible" class='lises'>
          <!-- 遍历subList -->
          <li v-for="subItem in item.subList" :key="subItem.id" >
               <router-link :to="subItem.url">
                {{ subItem.title }}
                </router-link>
          </li>
        </ol>
      </li>
    </ul>

  </section>
</template>
<style scope>
ul,li,li{
  list-style: none;
}
a{
  text-decoration: none;
}
.box{
  width: 100%;
  position:absolute;
 background-color: rgba(164,174,224,.11);
 z-index: 99999;
 font-size: 1.2rem;
top:3rem;
  left: 0;
}
.uls {
  width: 100%;
 position: relative;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  display: none;
}
.lis {
  width:20%;
  line-height: 3.125rem;
  text-align: center;
 
}
.lises {
  /* width: 18.75rem; */
  width: 100%;
  background:#fff;
  color: black;
  display: flex;
justify-content: flex-start;
  flex-wrap: wrap;
  position:absolute;
    top: 12.5rem;
    left:0;
}
.lises li{
  width:25%;
  margin-top:0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
  line-height: 1;
}
@media (max-width: 750px) {
 .uls {
   display: flex;
  }

}
</style>
<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          title: "首页",
          subList: [],
          visible: false,
        },
        {
          id: 2,
          title: "基础",
          subList: [
            {
              title:"批阅教师管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"函授站列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"年级列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"层次列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"专业列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"管理员列表",
              url:'../HelloWorld.vue'
            },
                 {
              title:"总部教师列表",
              url:'../HelloWorld.vue'
            },
                    {
              title:"函授站教师列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"课程库",
              url:'../HelloWorld.vue'
            },
                 {
              title:"学生报税信息",
              url:'../HelloWorld.vue'
            },
                     {
              title:"学期管理",
              url:'../HelloWorld.vue'
            },
          ],
          url:[
           
          ],
          visible: false,
        },
          {
          id:3,
          title: "通知",
          subList: [
             {
              title:"通知公告",
              url:'../HelloWorld.vue'
            },
                  {
              title:"学习必备",
              url:'../HelloWorld.vue'
            },
            {
              title:"短信管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"新生短信",
              url:'../HelloWorld.vue'
            },
            {
              title:"学生短信",
              url:'../HelloWorld.vue'
            },
                {
              title:"短信审核",
              url:'../HelloWorld.vue'
            },
            {
              title:"短信详情",
              url:'../HelloWorld.vue'
            },
            {
              title:"短信统计",
              url:'../HelloWorld.vue'
            },
                 {
              title:"学校短信统计",
              url:'../HelloWorld.vue'
            },
           {
              title:"短信模块",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
      {
          id:4,
          title: "招生",
          subList: [
             {
              title:"招生批次",
              url:'../HelloWorld.vue'
            },
                  {
              title:"站点设置",
              url:'../HelloWorld.vue'
            },
            {
              title:"招生须知",
              url:'../HelloWorld.vue'
            },
            {
              title:"录取信息",
              url:'../HelloWorld.vue'
            },
            {
              title:"录取发布",
              url:'../HelloWorld.vue'
            },
                {
              title:"预报名信息",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
        {
          id:5,
          title: "教务",
          subList: [
             {
              title:"学期管理",
              url:'../HelloWorld.vue'
            },
                  {
              title:"教学计划",
              url:'../HelloWorld.vue'
            },
              {
              title:"选课统计",
              url:'../HelloWorld.vue'
            },
            {
              title:"课程选课数据",
              url:'../HelloWorld.vue'
            },
            {
              title:" 在线作业管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"在线考试管理",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                {
          id:6,
          title: "学籍",
          subList: [
             {
              title:"未生成学号列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"报到列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"已生成学号列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"注册列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"注册信息统计",
              url:'../HelloWorld.vue'
            },
              {
              title:"学生管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"人数统计",
              url:'../HelloWorld.vue'
            },
            {
              title:"学生统计分类",
              url:'../HelloWorld.vue'
            },
            {
              title:" 学籍异动管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"休学管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 退学管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 肄业管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 材料申请",
              url:'../HelloWorld.vue'
            },
                {
              title:" 学籍自查",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                    {
          id:7,
          title: "考务",
          subList: [
             {
              title:"场次管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"批次管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"考试数据-笔试",
              url:'../HelloWorld.vue'
            },
            {
              title:"考试数据-在线",
              url:'../HelloWorld.vue'
            },
            {
              title:"考试数据管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"巡考教师安排",
              url:'../HelloWorld.vue'
            },
            {
              title:"考试安排进度",
              url:'../HelloWorld.vue'
            },
            {
              title:"考场查看",
              url:'../HelloWorld.vue'
            },
            {
              title:"审核考场时间",
              url:'../HelloWorld.vue'
            },
            {
              title:"签到教师列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"考场签到信息",
              url:'../HelloWorld.vue'
            },
            {
              title:"考生签到列表",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                {
          id:8,
          title: "教务",
          subList: [
             {
              title:"学期管理",
              url:'../HelloWorld.vue'
            },
                  {
              title:"教学计划",
              url:'../HelloWorld.vue'
            },
              {
              title:"选课统计",
              url:'../HelloWorld.vue'
            },
            {
              title:"课程选课数据",
              url:'../HelloWorld.vue'
            },
            {
              title:" 在线作业管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"在线考试管理",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                {
          id:9,
          title: "学籍",
          subList: [
             {
              title:"未生成学号列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"报到列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"已生成学号列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"注册列表",
              url:'../HelloWorld.vue'
            },
            {
              title:"注册信息统计",
              url:'../HelloWorld.vue'
            },
              {
              title:"学生管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"人数统计",
              url:'../HelloWorld.vue'
            },
            {
              title:"学生统计分类",
              url:'../HelloWorld.vue'
            },
            {
              title:" 学籍异动管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"休学管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 退学管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 肄业管理",
              url:'../HelloWorld.vue'
            },
            {
              title:" 材料申请",
              url:'../HelloWorld.vue'
            },
                {
              title:" 学籍自查",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                    {
          id:10,
          title: "成绩",
          subList: [
             {
              title:"免考审核",
              url:'../HelloWorld.vue'
            },
              {
              title:"考试成绩审核",
              url:'../HelloWorld.vue'
            },
              {
              title:" 考试成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"离线成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"在线成绩管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"免考成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"小学分成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"毕业设计成绩",
              url:'../HelloWorld.vue'
            },
            {
              title:"毕业大作业成绩",
              url:'../HelloWorld.vue'
            },
            {
              title:"交互成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"总评成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"考试成绩查询",
              url:'../HelloWorld.vue'
            },
             {
              title:"成绩修改审核",
              url:'../HelloWorld.vue'
            },
             {
              title:"学生成绩查询",
              url:'../HelloWorld.vue'
            },
             {
              title:"学生成绩统计",
              url:'../HelloWorld.vue'
            },
             {
              title:"生成分析数据",
              url:'../HelloWorld.vue'
            },
                {
              title:"考试成绩分析",
              url:'../HelloWorld.vue'
            },
                {
              title:"考试成绩汇总",
              url:'../HelloWorld.vue'
            },
                {
              title:"总评成绩分析",
              url:'../HelloWorld.vue'
            },
                {
              title:"总评成绩汇总",
              url:'../HelloWorld.vue'
            }, 
          ],
          visible: false,
        },
                            {
          id: 11,
          title: "毕业",
          subList: [
             {
              title:"批次管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"考试成绩审核",
              url:'../HelloWorld.vue'
            },
              {
              title:" 考试成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"离线成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"在线成绩管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"免考成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"小学分成绩录入",
              url:'../HelloWorld.vue'
            },
            {
              title:"毕业设计成绩",
              url:'../HelloWorld.vue'
            },
            {
              title:"毕业大作业成绩",
              url:'../HelloWorld.vue'
            },
            {
              title:"交互成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"总评成绩管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"发布成绩",
              url:'../HelloWorld.vue'
            },
             {
              title:"考试成绩查询",
              url:'../HelloWorld.vue'
            },
             {
              title:"成绩修改审核",
              url:'../HelloWorld.vue'
            },
             {
              title:"毕业生成绩查询",
              url:'../HelloWorld.vue'
            },
             {
              title:"学生成绩查询",
              url:'../HelloWorld.vue'
            },
                {
              title:" 学生成绩统计",
              url:'../HelloWorld.vue'
            },
                {
              title:"生成分析数据",
              url:'../HelloWorld.vue'
            },
                {
              title:"考试成绩分析",
              url:'../HelloWorld.vue'
            },
                {
              title:"考试成绩汇总",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                            {
          id:12,
          title: "费用",
          subList: [
             {
              title:"学费标准管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"个人标准管理",
              url:'../HelloWorld.vue'
            },
              {
              title:" 缴费规则",
              url:'../HelloWorld.vue'
            },
            {
              title:"缴费管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"学费管理",
              url:'../HelloWorld.vue'
            },
              {
              title:"学生费用明细",
              url:'../HelloWorld.vue'
            },
            {
              title:"订单管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"费用统计",
              url:'../HelloWorld.vue'
            },
            {
              title:"电子教材费",
              url:'../HelloWorld.vue'
            },
            {
              title:"财务对账",
              url:'../HelloWorld.vue'
            },
            {
              title:"结算信息查询",
              url:'../HelloWorld.vue'
            },
            {
              title:"费用null",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                            {
          id:13,
          title: "教材",
          subList: [
             {
              title:"教材列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"教材类别管理",
              url:'../HelloWorld.vue'
            },
              {
              title:" 课程关联教材",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                           {
          id:14,
          title: "统计",
          subList: [
             {
              title:"学习记录查询",
              url:'../HelloWorld.vue'
            },
              {
              title:"在线作业查询1",
              url:'../HelloWorld.vue'
            },
              {
              title:" 高基报表",
              url:'../HelloWorld.vue'
            },
                 {
              title:"登录统计日志",
              url:'../HelloWorld.vue'
            },
                 {
              title:"学生学习过程统计",
              url:'../HelloWorld.vue'
            },
                 {
              title:" 教师督学助学统计",
              url:'../HelloWorld.vue'
            },
                 {
              title:"课程督学助学统计",
              url:'../HelloWorld.vue'
            },
                 {
              title:"课程学习过程统计",
              url:'../HelloWorld.vue'
            },
              {
              title:"学生学习记录",
              url:'../HelloWorld.vue'
            },
                 {
              title:" 行为日志",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
        {
          id:15,
          title: "教材",
          subList: [
             {
              title:"教材列表",
              url:'../HelloWorld.vue'
            },
              {
              title:"教材类别管理",
              url:'../HelloWorld.vue'
            },
              {
              title:" 课程关联教材",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
                           {
          id:16,
          title: "系统",
          subList: [
             {
              title:"系统用户",
              url:'../HelloWorld.vue'
            },
              {
              title:"角色管理",
              url:'../HelloWorld.vue'
            },
              {
              title:" 分配权限管理",
              url:'../HelloWorld.vue'
            },
                 {
              title:"权限设置",
              url:'../HelloWorld.vue'
            },
                 {
              title:"学生账号管理",
              url:'../HelloWorld.vue'
            },
                 {
              title:" 教师账号管理",
              url:'../HelloWorld.vue'
            },
            {
              title:"函授站账号管理",
              url:'../HelloWorld.vue'
            },
          ],
          visible: false,
        },
      ],
    };
  },
  methods: {
    tab(index) {  
         this.list[index].visible = !this.list[index].visible;
    },
  },
};
</script>